<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="check-number">
        <div class="page-progress-wrap">
          <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.title : '' }}</h3>
          <div class="progress-box">
            <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].image_link: ''" alt="">
            <span>Input</span>
          </div>
          <div class="progress-box">
            <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].image_link: ''" alt="">
            <span>Confirm</span>
          </div>
          <div class="done-box">
            <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].image_link: ''" alt="">
            <span>Done</span>
          </div>
        </div>
        <form id="form">
          <label class="form-label">{{ !_.isEmpty(dataDetails) ? dataDetails.mobile_no_label : '' }}</label>
          <div class="receiver-number-box digi-number">
            <div class="country-box" @click="showCountryList">
              <img v-if="numberFlag" :src="pictureBaseUrl+'/'+numberFlag" alt="">
              <img v-else :src="!_.isEmpty(dataDetails) ? pictureBaseUrl+'/'+dataDetails.available_country.bn.flag :''"
                   alt="">
              <span v-if="numberPrefix">{{ !_.isEmpty(dataDetails) ? numberPrefix : '' }}</span>
              <span v-else>{{ !_.isEmpty(dataDetails) ? dataDetails.available_country.bn.prefix : '' }}</span>
            </div>
            <div class="input-number">
              <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" v-model="receiverNumber" v-validate="'required|numeric'"  name="mobile_no" id="mobile_no"
                     style="text-align:left"
                     maxlength="12"  minlength="8" autocomplete="off" :class="{ 'has-error': errors.has('mobile_no') }">
            </div>
          </div>
<!--          <div class="help text-danger" v-show="errors.has('mobile_no')">-->
<!--            {{ errors.first("mobile_no") }}-->
<!--          </div>-->

          <div id="div_id_select_operator" style="display: none;">
            <label class="form-label">Select Operator</label>
          </div>
          <div id="loading" style="display: none">
            <p style="text-align: center;font-size: 19px;color: red;">Loading Operators <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i></p>
          </div>
          <div id="operator_block">
            <div class="change-operator">
              <img id="operator_small_1" class="operator_small" :src="pictureBaseUrl+'/'+selectedOperatorImageUrl" alt="Airtel-Prepaid"
                   style=" margin: 0 auto; height: 35px;" :style="selectedOperatorShow?'display: block  ;':'display: none ;'">
              <button id="changeOperator" @click="toggoleOperatorChoose" type="button" class="btn" :style="selectedOperatorShow?'display: block;':'display: none;'">{{ !_.isEmpty(dataDetails) ? dataDetails.change_operator_btn_text : '' }}</button>
            </div>
            <div id="div_id_all_operator" class="operator-wrap" style="display: block;" v-if="allOperatorShow">
              <ul>
                <li v-for="operator in dataDetails.operators">
                  <label class="operator">
                    <input type="radio" name="operator_id" id="operator_1" value="1">
                    <div class="operator-box" @click="submitOperator(operator.id,operator.image)" :style="[ receiverOperatorId === operator.id ? { 'background': '#ffce00'} : '']">
                        <img :src="pictureBaseUrl+'/'+operator.image" :alt="operator.name">
                        <div class="checked-icon"><img :src="pictureBaseUrl+'/digi/images/checked-black.svg'" alt=""></div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <button type="button" class="btn justify-center" id="next_btn"
                  data-mobile_no_error_text="আপনার প্রদত্ত নাম্বারটি সঠিক নয়। অনুগ্রহ করে আপনার  নাম্বারটি কান্ট্রি কোড ছাড়া লিখুন।"
                  data-operator_error_text="এই  অপারেটর প্রযোজ্য নহে। " @click="submit" data-language="1">{{ !_.isEmpty(dataDetails) ? dataDetails.next_btn_text : '' }}<span
            class="arrow-icon"></span></button>
          <br>
          <div id="wrong_message"></div>
          <br>

          <br>
          <span style="font-size:.8em; color: red;text-align: center;display: block;" v-if="operatorId==1" >{{ !_.isEmpty(dataDetails) ? dataDetails.cashback_text_snd_line : '' }}</span>
          <span style="font-size:.8em; color: red;text-align: center;display: block;" v-if="operatorId==1">{{ !_.isEmpty(dataDetails) ? dataDetails.topup_capping_msg : '' }}</span>

        </form>
      </div>
      <div class="country-modal" id="countryModal" style="display: block;" v-if="showCountry">
        <div class="country-modal-inner">
          <div class="country-modal-close" @click="showCountryList">×</div>
          <div class="country-modal-content">
            <h3>{{ dataDetails.select_country_text }}</h3>
            <ul>
              <li v-for="(country,index) in dataDetails.available_country" :key="index">
                <label @click.prevent="submitCountry(index,country.flag,country.prefix)">
                  <div>
                    <img :src="pictureBaseUrl+'/'+country.flag" alt="">
                    <span>{{ country.name }}</span> <span>{{ country.prefix }}</span>
                  </div>
                  <input type="radio" name="country" value="" checked="">
                  <span class="radio-btn"></span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'

export default {
  name: 'topUpList',

  data () {
    return {
      apiUrl: 'content/internet-topup-page',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      receiverNumber: this.$route.params.receiverNumber ?? (sessionStorage.getItem('receiverMsisdn') ?? ''),
      operatorApiUrl: 'get-countrywise-operator-list',
      showCountry: false,
      numberPrefix: '',
      numberFlag: '',
      is_loading: true,
      service: '',
      receiverOperatorId: sessionStorage.getItem('receiverOperatorId') ?? '',
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language'),
      languageName: sessionStorage.getItem('languageName') ?? '',
      allOperatorShow: false,
      selectedOperatorShow: false,
      selectedOperatorImageUrl: sessionStorage.getItem('receiverOperatorImageUrl') ?? '',
      firstThreeDigitOfReceiverNumber: null
    }
  },
  mounted () {
    this.init()
    if (this.selectedOperatorImageUrl !== '') {
      this.selectedOperatorShow = true
    }
  },
  computed: {

  },
  watch: {
    receiverNumber (value) {
      if (value.length >= 3) {
        this.allOperatorShow = true

        this.firstThreeDigitOfReceiverNumber = value.substring(0, 3)
        for (let i = 0; i < this.dataDetails.operators.length; i++) {
          for (let j = 0; j < this.dataDetails.operators[i].prefix.length; j++) {
            if (this.dataDetails.operators[i].prefix[j] === this.firstThreeDigitOfReceiverNumber) {
              this.selectedOperatorImageUrl = this.dataDetails.operators[i].image
              this.selectedOperatorShow = true
              this.allOperatorShow = false
              sessionStorage.setItem('receiverOperatorId', this.dataDetails.operators[i].id)
              sessionStorage.setItem('receiverOperatorImageUrl', this.dataDetails.operators[i].image)
              this.receiverOperatorId = this.dataDetails.operators[i].id
            }
          }
        }
      }
      if (value.length < 3) {
        this.allOperatorShow = false
        this.selectedOperatorShow = false
        this.firstThreeDigitOfReceiverNumber = null
      }
    }
  },
  methods: {
    async init () {
      this.is_loading = true
      this.service = this.$route.params.type
      if (this.$route.params.type === 'talktime_topup') {
        this.apiUrl += '/no'
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })

      Object.entries(this.dataDetails.available_country).forEach(entry => {
        const [key, value] = entry
        if (key === this.languageName) {
          this.numberPrefix = value.prefix
          this.numberFlag = value.flag
          sessionStorage.setItem('receiverCountry', this.languageName)
        }
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service + 'list', this.msisdn, this.languageId, this.sessionId)
    },
    showCountryList () {
      this.showCountry = !this.showCountry
    },
    submitCountry (country, flag, prefix) {
      this.getAvailableOperator(country)
      this.showCountryList()
      this.numberPrefix = prefix
      this.numberFlag = flag
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, { operatorId: this.operatorId, languageId: this.languageId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    getAvailableOperator (country) {
      sessionStorage.setItem('receiverCountry', country)
      axios
        .post(this.operatorApiUrl, '', { params: { language: country } })
        .then((response) => {
          this.dataDetails.operators = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-undef
          reject(error)
        })
        .finally(() => {
        })
    },
    submitOperator (operatorId, image) {
      sessionStorage.setItem('receiverOperatorId', operatorId)
      sessionStorage.setItem('receiverOperatorImageUrl', image)
      this.allOperatorShow = !this.allOperatorShow
      this.selectedOperatorShow = true
      this.selectedOperatorImageUrl = image
      this.receiverOperatorId = operatorId
    },
    toggoleOperatorChoose () {
      this.allOperatorShow = !this.allOperatorShow
    },
    submit () {
      if (this.receiverNumber.length < 8 || this.receiverNumber.length > 12) {
        this.$toaster.error(this.dataDetails.mobile_no_error_text)
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          sessionStorage.setItem('serviceType', 'topup')
          sessionStorage.setItem('receiverMsisdn', this.receiverNumber)
          this.$router.push({ name: 'topUpPackages', params: { type: this.service } })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
