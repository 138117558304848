import { render, staticRenderFns } from "./topUpList.vue?vue&type=template&id=73544a77&scoped=true"
import script from "./topUpList.vue?vue&type=script&lang=js"
export * from "./topUpList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73544a77",
  null
  
)

export default component.exports